import { render, staticRenderFns } from "./ModalUpgrade.vue?vue&type=template&id=a8dec15e&scoped=true&"
import script from "./ModalUpgrade.vue?vue&type=script&lang=ts&"
export * from "./ModalUpgrade.vue?vue&type=script&lang=ts&"
import style0 from "./ModalUpgrade.vue?vue&type=style&index=0&id=a8dec15e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8dec15e",
  null
  
)

export default component.exports
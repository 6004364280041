
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "PlansUnderConstruction",
  components: {},
})
export default class PlansUnderConstruction extends Vue {
  wrapperHeight: number = 0;

  get wrapperStyle() {
    return {
      height: this.wrapperHeight + "px",
    };
  }

  mounted() {
    // should be height of 100% so we put this.height = parent's height
    const viewContentWrapper = document.getElementsByClassName("section-wrapper")[0] as HTMLDivElement;
    this.wrapperHeight = viewContentWrapper.clientHeight;
  }
}


import Carousel from "@/components/common/Carousel.vue";
import Plan from "@/components/pricingTable/Plan.vue";
import PricingTableDisclaimers from "@/components/pricingTable/PricingTableDisclaimers.vue";
import LightUpgradeSuccessRegular from "@/components/views/subscriptions/lightUpgrade/LightUpgradeSuccessRegular.vue";
import LightUpgradeSuccessAfterExpiration from "@/components/views/subscriptions/lightUpgrade/LightUpgradeSuccessAfterExpiration.vue";
import ModalBuy from "@/components/views/subscriptions/modals/buyProcess/ModalBuy.vue";
import ModalGetAQuote from "@/components/views/subscriptions/modals/ModalGetAQuote.vue";
import ModalUpgrade from "@/components/views/subscriptions/modals/ModalUpgrade.vue";
import { CarouselProps } from "@/types/carousel";
import { FlatSubscription, PlanContext, PlanModel } from "@/types/localtypes";
import {
  GetAQuoteRequest,
  isRestClientError,
  PackageCore,
  PaymentType,
  storeErrorsMapping,
  SubscriptionDTO,
  SubscriptionTypes,
} from "@jfrog-ba/myjfrog-common";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import PromoPT15USDHeader from "@/components/landingPages/quickPurchase/promotions/proteam15usd/PromoPT15USDHeader.vue";
import { cookiesService } from "@/services/cookies";

@Component({
  name: "UpgradeCarousel",
  components: { Carousel, PricingTableDisclaimers, PromoPT15USDHeader },
})
export default class UpgradeCarousel extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Getter("lightUiEnabled", { namespace: "application" })
  lightUiEnabled!: boolean;
  @Prop({ default: null })
  private subscription!: FlatSubscription;
  @Prop({ default: false })
  private tiersPricesLoaded!: boolean;
  @Prop({ default: undefined })
  private context!: PlanContext;
  @Getter("subscriptions", { namespace: "subscriptions" })
  subscriptions!: SubscriptionDTO[];
  @Action("setSubscriptions", { namespace: "subscriptions" })
  setSubscriptions!: (subscriptions: SubscriptionDTO[]) => void;

  subscriptionTypes: SubscriptionTypes[] = [];
  allPlans: PlanModel[] = [];

  lightUpgradeState = {
    inSuccess: false,
    buyFromExpiredTrial: false,
  };

  get displayLightUpgradeSuccessCard() {
    return this.lightUpgradeState.inSuccess;
  }

  get lightUpgradeSuccessCardComponent() {
    return this.lightUpgradeState.buyFromExpiredTrial ? LightUpgradeSuccessAfterExpiration : LightUpgradeSuccessRegular;
  }

  async created() {
    try {
      this.setLoading(true);
      this.subscriptionTypes = await this.$jfSubscriptions.getSubscriptionTypes(this.subscription.accountNumber);
      this.createPlans();
      this.listenToBusEvent();
      this.setLoading(false);
    } catch (e) {
      this.$log.error(e);
      this.$jfNotification.error({ text: this.$jfMessages.subscriptions_list_fetch_failed });
      this.setLoading(false);
    }
  }

  private createPlans() {
    const proTeam =
      this.subscriptionTypes && this.subscriptionTypes.find(value => value.paymentType === "PRO_TEAM_MONTHLY");
    const entTeam =
      this.subscriptionTypes && this.subscriptionTypes.find(value => value.paymentType === "ENTERPRISE_MONTHLY");
    const entXTeam =
      this.subscriptionTypes && this.subscriptionTypes.find(value => value.paymentType === "ENTERPRISE_X_MONTHLY");
    const pro = this.subscriptionTypes && this.subscriptionTypes.find(value => value.paymentType === "MONTHLY");
    const proX = this.subscriptionTypes && this.subscriptionTypes.find(value => value.paymentType === "MONTHLY_PROX");
    const pipelinesPro =
      this.subscriptionTypes && this.subscriptionTypes.find(value => value.optionType === "PIPELINES_PRO");
    const pipelinesProX =
      this.subscriptionTypes && this.subscriptionTypes.find(value => value.optionType === "PIPELINES_PRO_X");

    if (
      !proTeam ||
      !entTeam ||
      !pro ||
      !proX ||
      !pipelinesPro ||
      !pipelinesProX ||
      !pro.paymentType ||
      !proX.paymentType ||
      !entXTeam
    ) {
      this.$log.error("Some paymentTypes are not found");
      this.notifyError(this.$jfMessages.subscriptions_upgrade_error_unknown);
      return;
    }

    const meta = this.subscription.meta;

    if (meta.isFree) {
      this.allPlans.push(
        this.$jfPricing.createPlan(
          {
            currentPlan: false,
            canPurchasePipelines: this.canPurchasePipelines,
            buttonText: "Upgrade Now",
            isProcessable: this.actionIsProcessable("PRO_TEAM_MONTHLY"),
            core: "DEVOPS",
          },
          proTeam,
          [],
        ),
      );
    }

    if (meta.isFree || meta.isProTeam) {
      this.allPlans.push(
        this.$jfPricing.createPlan(
          {
            subscriptionType: "ENTERPRISE_MONTHLY",
            currentPlan: false,
            canPurchasePipelines: this.canPurchasePipelines,
            buttonText: meta.isFree || meta.isMonthlyPayment ? "Upgrade Now" : "Contact Us",
            isProcessable: this.actionIsProcessable("ENTERPRISE_MONTHLY"),
            shouldMentionOnlyCurrentPlan: meta.isProTeam,
            groupXrayPremium: true,
            // for showing total in SubscriptionPackage
            isUpgrade: meta.isProTeam,
            core: "DEVOPS",
            forceAdditionalType: true,
          },
          entTeam,
          [entXTeam],
        ),
      );
    }

    if (meta.isJCR) {
      this.allPlans.push(
        this.$jfPricing.createPlan(
          {
            currentPlan: false,
            canPurchasePipelines: this.canPurchasePipelines,
            buttonText: "Upgrade Now",
            isProcessable: this.actionIsProcessable(pro.paymentType),
            isUpgrade: true,
            core: "DEVOPS",
          },
          pro,
          [pipelinesPro],
        ),
      );
    }

    if (meta.isJCR || meta.isCloudPro || (meta.isCloudProX && meta.isTrialXray)) {
      this.allPlans.push(
        this.$jfPricing.createPlan(
          {
            currentPlan: false,
            canPurchasePipelines: this.canPurchasePipelines,
            buttonText: "Upgrade Now",
            isProcessable: this.actionIsProcessable(proX.paymentType),
            isUpgrade: true,
            isPipelinesAvailable: meta.hasPipelines && !meta.isPipelinesBlocked,
            core: "DEVOPS",
          },
          proX,
          [pipelinesProX],
        ),
      );
    }
    if (!meta.isEnterprise && !meta.isFree && !meta.isProTeam && !meta.isEnterpriseTeam) {
      this.allPlans.push(
        this.$jfPricing.createPlan({
          subscriptionType: "CLOUD_ENTERPRISE",
          buttonText: "Contact Us",
          canPurchasePipelines: this.canPurchasePipelines,
          isProcessable: this.actionIsProcessable("DEDICATED"),
          isUpgrade: true,
          core: "DEVOPS",
        }),
      );
    }

    this.allPlans.push(
      this.$jfPricing.createPlan({
        subscriptionType: "CLOUD_ENTERPRISE_PLUS",
        buttonText: "Contact Us",
        canPurchasePipelines: this.canPurchasePipelines,
        isProcessable: this.actionIsProcessable("ENTERPRISE_PLUS"),
        isUpgrade: true,
        shouldMentionOnlyCurrentPlan: meta.isEnterpriseTeam,
        groupXrayPremium: true,
        core: "DEVOPS",
        rateText: "Full Devops Platform",
      }),
    );

    if (!this.subscription.hasBusinessSecurity) {
      this.allPlans.push(
        this.$jfPricing.createPlan({
          subscriptionType: "ENT",
          currentPlan: false,
          canPurchasePipelines: true,
          buttonText: "Contact Us",
          actionBtnDisabled: false,
          isProcessable: true,
          core: "DEVSECOPS",
        }),
      );
    }
    this.allPlans.push(
      this.$jfPricing.createPlan({
        subscriptionType: "CLOUD_ENTERPRISE_PLUS",
        currentPlan: false,
        canPurchasePipelines: true,
        buttonText: "Contact Us",
        actionBtnDisabled: false,
        isProcessable: true,
        core: "DEVSECOPS",
        rateText: "Full Devops Platform",
      }),
    );
  }

  get plans() {
    return this.fillTooltipNumbers(this.allPlans);
  }

  fillTooltipNumbers(plans: PlanModel[]) {
    const tags = this.$jfPricing.extractPlanDisclaimerTags(plans);
    return plans.map(p => {
      return {
        ...p,
        content: p.content.map(c => {
          if (!c.tooltip) {
            return c;
          }
          //Force the tooltip design for upgrade page
          const tooltipNumber = c.tooltip.forceNumber ? c.tooltip.forceNumber : tags.indexOf(c.tooltip.tag) + 1;
          return { ...c, tooltip: { ...c.tooltip, number: tooltipNumber } };
        }),
      };
    });
  }

  get hideMenu() {
    return this.subscription.meta.isEnterpriseTeam && this.subscription.hasBusinessSecurity;
  }

  get canPurchasePipelines() {
    const { isPipelinesAvailableInRegion, isJfrogUnified } = this.subscription.meta;
    return !!isPipelinesAvailableInRegion && !!isJfrogUnified;
  }

  notifyError(errorMessage: string) {
    this.globalBus.$emit("notifyError", errorMessage);
  }

  // handleOpenCalculator(calculatorPaymentType: PaymentType) {
  //   this.$emit("openCalculator", calculatorPaymentType);
  // }

  get carouselData(): CarouselProps {
    return {
      context: this.context,
      components: this.plans.map((plan, index) => ({
        component: Plan,
        componentProperties: {
          subscriptionTypes: this.subscriptionTypes,
          class: "isDisabled",
          onPlanSelected: this.onSelectPlan,
          isCurrent: plan.currentPlan,
          shadowType: "dark-shadow",
          ...plan,
          id: index,
          context: this.context,
          core: plan.core,
        },
      })),
      initialState: {
        showAll: true,
        initialIndex: 0,
      },
      hideMenu: this.hideMenu,
    };
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  showModal(text: string) {
    this.$jfModal.confirm({
      body: text,
      buttonsText: { confirm: "Close" },
      size: "md",
      headerBorder: false,
      footerBorder: false,
      onConfirm: () => true,
      displayCancelButton: false,
    });
  }

  actionIsProcessable(paymentType: PaymentType) {
    if (paymentType === "MONTHLY_PROX") {
      // upgrade to proX > need to check some conditions before allowing the upgrade process
      return !this.upgradePageNotProcessableExplanation;
    }
    if (this.subscription.meta.isJCR && paymentType === "MONTHLY") {
      // upgrade to pro
      return true;
    }
    const processablePaymentTypes: PaymentType[] = [
      "PRO_TEAM_MONTHLY",
      "ENTERPRISE_MONTHLY",
      "ENTERPRISE_X_MONTHLY",
      "ENTERPRISE_PLUS",
    ];
    if (processablePaymentTypes.includes(paymentType)) {
      return true;
    }
    return false;
  }

  get upgradePageNotProcessableExplanation() {
    return this.$jfSubscriptions.getUpgradePageNotProcessableExplanation(this.subscription.meta);
  }

  private onSelectPlan(selectedPlanTitle: string, selectedPlanCore: PackageCore) {
    const selectedPlan: PlanModel = this.allPlans.find(
      plan => plan.title === selectedPlanTitle && plan.core === selectedPlanCore,
    ) as PlanModel;
    if (
      (this.subscription.meta.isPrepaidPayment && selectedPlan.isEnterprisePlan) ||
      selectedPlan.isEnterprisePlusPlan ||
      selectedPlan.core === "DEVSECOPS"
    ) {
      return this.displayGetAQuoteModal(selectedPlan);
    }
    const upgradePageNotProcessableExplanation = this.upgradePageNotProcessableExplanation;
    if (!this.actionIsProcessable(selectedPlan.paymentType) && upgradePageNotProcessableExplanation) {
      this.$jfSubscriptions.sendSubscriptionActionRequest({
        reason: upgradePageNotProcessableExplanation.reason,
        actionType: "upgrade",
        serverName: this.serverName,
        marketoCookie: this.$jfMarketo.getCookie(),
      });
      return this.showModal(upgradePageNotProcessableExplanation.message);
    }

    if (this.subscription.status === "In Progress") {
      return this.showModal(this.$jfMessages.subscription_activation_in_progress);
    }

    return this.upgradeActionRequired(selectedPlan);
  }

  upgradeActionRequired(selectedPlan: PlanModel) {
    if (
      this.subscription.meta.isFree &&
      (selectedPlan.paymentType === "PRO_TEAM_MONTHLY" || selectedPlan.paymentType === "ENTERPRISE_MONTHLY")
    ) {
      return this.showBuyModal(selectedPlan);
    }
    this.$jfModal.showCustom(
      ModalUpgrade,
      "md",
      {
        selectedPlan,
        subscription: this.subscription,
        onApprove: async (paymentType: PaymentType) => this.processUpgrade(paymentType),
      },
      {
        forceDisplayCancelIcon: this.isMobile,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  setLoading(isLoading: boolean) {
    // this.$emit("loading", isLoading);
    this.globalBus.$emit("toggleUpgradeModalLoadingSpinner", isLoading);
  }

  async processUpgrade(paymentType: PaymentType) {
    try {
      const isJcr = this.subscription.meta.isJCR;
      this.setLoading(true);

      const subscriptionDTO = await this.$jfSubscriptions.upgradeSubscription(
        this.subscription.accountNumber,
        this.subscription.paymentType as PaymentType,
        paymentType,
        this.subscription.serverName,
      );

      await this.setSubscriptions(
        this.subscriptions.map(subscription =>
          subscriptionDTO.accountNumber === subscription.accountNumber ? subscriptionDTO : subscription,
        ),
      );

      if (this.lightUiEnabled) {
        this.onLightUpgradeSuccess({ buyFromExpiredTrial: false });
      } else {
        const upgradeMessageFromJCR =
          paymentType === "MONTHLY_PROX"
            ? this.$jfMessages.subscriptions_upgrade_proX_from_JCR_success
            : this.$jfMessages.subscriptions_upgrade_pro_from_JCR_success;

        this.$jfNotification.success({
          text: isJcr ? upgradeMessageFromJCR : this.$jfMessages.subscriptions_upgrade_proX_pro_success,
        });
        this.$router.push({ path: `/subscriptions`, params: { accountNumber: "" + this.subscription.accountNumber } });
      }
    } catch (e) {
      this.$log.error(e);
      let errorMessage = this.$jfMessages.subscriptions_upgrade_error_unknown;
      if (isRestClientError(e) && e.httpBody) {
        if (e.httpBody.result === storeErrorsMapping.upgradeSubscription.narcissusTaskInProgress) {
          errorMessage = this.$jfMessages.subscriptions_narcissus_task_in_progress;
        } else {
          errorMessage = this.$jfMessages.generateMailLink(e.httpBody.message || (e.httpStatusText as string));
        }
      }
      this.notifyError(errorMessage);
    } finally {
      this.setLoading(false);
      this.$jfModal.dismiss();
    }
  }

  showBuyModal(selectedPlan: PlanModel) {
    this.$jfModal.showCustom(
      ModalBuy,
      "md",
      {
        subscription: this.subscription,
        selectedPlan: selectedPlan,
        subscriptionTypes: this.subscriptionTypes,
      },
      {
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  get serverName() {
    return this.subscription && this.subscription.serverName;
  }

  displayGetAQuoteModal(selectedPlan: PlanModel) {
    const actionType: GetAQuoteRequest["actionType"] = "upgrade";
    this.$jfModal.showCustom(
      ModalGetAQuote,
      "lg",
      {
        actionType: actionType,
        serverName: this.serverName,
        isEnterprisePlus: selectedPlan.isEnterprisePlusPlan,
        title: `${selectedPlan.title}${selectedPlan.core === "DEVSECOPS" ? " with Advanced Security Pack" : ""}`,
        core: selectedPlan.core,
      },
      {
        title: selectedPlan.title + " Subscription",
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  onLightUpgradeSuccess(config: { buyFromExpiredTrial: boolean }) {
    this.lightUpgradeState.inSuccess = true;
    this.lightUpgradeState.buyFromExpiredTrial = config.buyFromExpiredTrial;
    cookiesService.setLightUpgradeInSuccess("" + this.subscription.accountNumber);
  }

  busEvent: { [key: string]: (...params: any[]) => any } = {
    lightUpgradeSuccess: this.onLightUpgradeSuccess,
  };

  listenToBusEvent() {
    for (let key in this.busEvent) {
      this.globalBus.$on(key, this.busEvent[key]);
    }
  }

  beforeDestroy() {
    for (let key in this.busEvent) {
      this.globalBus.$off(key, this.busEvent[key]);
    }
  }
}


import LoadingMask from "@/components/common/LoadingMask.vue";
import HeaderForPurchase from "@/components/views/subscriptions/modals/headerPurchaseProcess/HeaderForPurchase.vue";
import { LoadingMaskProps } from "@/types/loadingMask";
import { FlatSubscription, PlanModel } from "@/types/localtypes";
import { PaymentType } from "@jfrog-ba/myjfrog-common";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "ModalUpgrade",
  components: {
    LoadingMask,
    HeaderForPurchase,
  },
})
export default class ModalUpgrade extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Prop() private onApprove!: (paymentType: PaymentType) => void;
  @Prop() private subscription!: FlatSubscription;
  @Prop() private selectedPlan!: PlanModel;
  isJCR = false;
  isLoadingData = false;
  paymentType: PaymentType | null = null;

  get dataGtmPage() {
    return "upgrade-process";
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoadingData,
    };
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  onUpdatePaymentType(paymentType: PaymentType) {
    this.paymentType = paymentType;
  }

  get canPurchasePipelines() {
    const { isPipelinesAvailableInRegion, isJfrogUnified } = this.subscription.meta;
    return isPipelinesAvailableInRegion && isJfrogUnified;
  }

  get isPipelinesForced() {
    const { hasPipelines, isPipelinesBlocked } = this.subscription.meta;
    return hasPipelines && !isPipelinesBlocked;
  }

  dismiss() {
    this.$jfModal.dismiss();
  }

  onSubmit() {
    this.paymentType && this.onApprove(this.paymentType);
  }

  displayLoadingSpinner(isLoading: boolean) {
    this.isLoadingData = isLoading;
  }

  busEvent: { [key: string]: (...params: any[]) => any } = {
    toggleUpgradeModalLoadingSpinner: this.displayLoadingSpinner,
  };

  created() {
    for (let key in this.busEvent) {
      this.globalBus.$on(key, this.busEvent[key]);
    }
  }

  beforeDestroy() {
    for (let key in this.busEvent) {
      this.globalBus.$off(key, this.busEvent[key]);
    }
  }

  mounted() {
    this.isJCR = !!this.subscription.meta.isJCR;
    // should be called in each custom modal in his mounted() method (it will trigger resizes and other global stuff).
    // Called automatically by Essentials in JS but doesn't work in TS components so we should trigger it manually.
    this.$jfModal.childComponentIsSet();
    this.loadInitialData();
  }
  loadInitialData() {
    this.paymentType = this.selectedPlan.paymentType;
  }
}
